<div
#resizeBox
cdkDrag
[cdkDragFreeDragPosition]="this.usesXY"
[cdkDragConstrainPosition]="dragConstrainPoint"
(cdkDragStarted)="startDragging($event)"
(cdkDragReleased)="updateDrag($event)"
[ngClass]="{
  'preview-mode': this.preview,
  'canvas-element': this.usesXY,
  'resizable': this.resizing,
  'compact': (this.usesWH && this.meta && this.meta.elements[this.metaIndex].h < 100)
}"
class="element type-{{type ? type : ''}}"
[ngStyle]="{
  'visibility': (this.meta && this.meta.elements[this.metaIndex].delist) ? 'hidden' : 'visible',
  'transform': attr && attr.transform,
  'width': this.usesWH && this.usesWH.w + 'px',
  'height': this.usesWH && this.usesWH.h + 'px',
  'z-index': (this.meta && this.meta.elements[this.metaIndex].depth) ? this.meta.elements[this.metaIndex].depth : null
}">

  <div cdkDragHandle *ngIf="!this.preview && this.usesXY" class="drag-btn btn"><ion-icon name="move-outline"></ion-icon></div>
  <div cdkDragHandle *ngIf="!this.preview && !this.usesXY" class="ion-hide"></div> <!-- Drag Disable Hack -->

  <ng-container *ngIf="!this.preview">
    <div (click)="actionWrapper()" *ngIf="action" class="setting-btn btn" [attr.data-tip]="(type === 'root') ? 'Customize Template' : null"><ion-icon name="settings-outline"></ion-icon></div>

    <!--
    <div (click)="startResizeEvent()" *ngIf="resizable" class="resize-btn btn"><ion-icon name="resize-outline"></ion-icon></div>
    <div (click)="mouseUp()" *ngIf="resizable && this.resizing" class="unresize-btn btn"><ion-icon name="checkmark-outline"></ion-icon></div>
    -->
    <!-- Delete Operandis -->

    <div *ngIf="this.usesXY" class="layer-btn btn" (click)="switchDepthMode($event)">
      <!--<ion-icon name="trash-bin-outline"></ion-icon>-->
      <ion-icon *ngIf="this.meta && this.meta.elements[this.metaIndex].depth === -99" name="caret-down-circle-outline"></ion-icon>
      <ion-icon *ngIf="this.meta && this.meta.elements[this.metaIndex].depth === 101" name="caret-up-circle-outline"></ion-icon>
      <ion-icon *ngIf="this.meta && ( this.meta.elements[this.metaIndex].depth === 'auto' || !this.meta.elements[this.metaIndex].depth )" name="remove-circle-outline"></ion-icon>
    </div>

    <div (mousedown)="startResizeEvent(resizeBox)" *ngIf="resizable" class="resize-btn btn"><ion-icon name="resize-outline"></ion-icon></div>

    <div *ngIf="this.usesXY" class="del-btn btn" (click)="deleteElement()"><ion-icon name="trash-bin-outline"></ion-icon></div>

    <div *ngIf="type === 'logo' && this.usesSwapable" class="mode-btn btn" (click)="modeElement()"><ion-icon name="repeat-outline"></ion-icon></div>
    

    <div *ngIf="(type === 'text' && brainstorming.isAvailiable()) || (type === 'text-container' && attr.brainstorm)" class="brainstorm-btn btn {{ type }}" (click)="brainstormAi(meta)">Ask AI</div>
    <div *ngIf="attr && attr.copy" class="copy-btn btn" (click)="copyContent()"><ion-icon name="copy-outline"></ion-icon></div>
    <div *ngIf="attr && attr.download" class="dl-btn btn" (click)="downloadContent()"><ion-icon name="cloud-download-outline"></ion-icon></div>
  </ng-container>

  <ng-container *ngIf="(type === 'text' || type === 'cta') && ready">
    <!--
    (onBlur)="blure($event)"
    (onFocus)="enterViewEdit($event)"
    (onFocusOut)="blure($event)"
    -->


    <div [ngStyle]="ignoreInvalidAttr(attr, meta, meta && meta.elements ? {
      
    } : {})"
    class="transform-basis"
    [ngClass]="{'text-box' : type === 'text', 'cta': type === 'cta' }">

      <editor
        #chatMCE
        class="font-{{ joinFont(attr.font) }}"

        [ngStyle]="this.usesXY ? {} : ignoreInvalidAttr(attr, meta, meta && meta.elements ? {
          'rotate': '0deg'
        } : {})"

        [(ngModel)]="value"
        (onChange)="change($event)"
        (input)="change($event)"
        (onInit)="init()"

        [inline]="true"
        [init]="{
          paste_as_text: true,
          base_url: '/tinymce',
          suffix: '.min',
          skin: 'oxide',
          placeholder: '',
          menubar: false,
          extended_valid_elements : 'b,i,u,div[*]',
          forced_root_block : 'div',
          plugins: [
            'paste'
          ],
          toolbar:
            'italic bold underline' + (this.usesWH ? '| alignleft aligncenter alignright' : '')
        }"
      ></editor>
      <!-- | alignleft aligncenter alignright -->
    </div>


  </ng-container>

  <ng-container *ngIf="type === 'image' || type === 'logo'">

    <div class="placeholder-by-logo" *ngIf="type === 'logo' && !meta.elements[metaIndex].data.value && meta.elements[metaIndex].mode !== 'text'" [ngStyle]="{
      'background-image': 'url(' +meta['media_logo']+ ')'
    }">
      <img class="hackload-img" *ngIf="imageOnly" [src]="meta['media_logo']"/>
    </div>

    <ng-container *ngIf="type === 'logo' && meta.elements[metaIndex].mode === 'text'">
      <div [ngStyle]="ignoreInvalidAttr(attr)" class="text-box logo" *ngIf="ready">

        <editor
          #chatMCE
          class="font-{{ joinFont(attr.font) }} ion-text-center"
          [ngStyle]="ignoreInvalidAttr(attr, meta, meta && meta.elements ? {
            'rotate': '0deg'
          } : {})"
          [(ngModel)]="value"
          (onChange)="change($event)"
          (input)="change($event)"
          (onInit)="init()"

          [inline]="true"
          [init]="{
            paste_as_text: true,
            base_url: '/tinymce',
            suffix: '.min',
            skin: 'oxide',
            placeholder: 'Your Text Goes Here...',
            menubar: false,
            extended_valid_elements : 'b,i,u,div',
            forced_root_block : 'div',
            plugins: [
              'paste'
            ],
            toolbar:
              'italic bold underline'
          }"
        ></editor>

      </div>
    </ng-container>

    <ng-container *ngIf="type === 'image' || ( type === 'logo' && meta.elements[metaIndex].mode != 'text' )">

      <div (click)="action && action()" *ngIf="meta.elements[metaIndex].data.value" class="image-base" [ngStyle]="{
        'height': '100%',
        'display': (meta.elements[metaIndex].data.show) ? 'block' : 'none',
        'background-color': meta.elements[metaIndex].data.color ? meta.elements[metaIndex].data.color : ('transparent')
      }">

        <!--
        Custom Image - Color
        -->
        <div *ngIf="meta.elements[metaIndex].data.value" class="image-base transform-basis" [ngStyle]="ignoreInvalidAttr(attr, meta, meta && meta.elements ? {

        } : {})"
        >
          <!--
          Custom Image - Image
          -->
          <img class="hackload-img" *ngIf="imageOnly" [src]="meta.elements[metaIndex].data.value"/>
        </div>
      </div>

      <div title="{{meta.elements[metaIndex].data.key}}" class="placeholder-by-key" *ngIf="type === 'image' && meta.elements[metaIndex].data.key && !meta.elements[metaIndex].data.value">
        <span>Upload your Image</span>
      </div>

    </ng-container>

  </ng-container>

  <ng-container *ngIf="type === 'icon'">

    <div *ngIf="value && value.src && !meta">

      <!-- [width]="value.size" [height]="value.size" -->
      <!--<ion-icon (click)="action && action()" [ngStyle]="attr" [name]="value"></ion-icon>-->
      <div *ngIf="value.color || attr.color" [ngStyle]="value.multicolor ? {} : getFilter(value.color || attr.color)">

        <img [width]="value.size || 32" [height]="value.size || 32" (click)="action && action()" [ngStyle]="attr" [src]="value.src"/>

        <img class="hackload-img" *ngIf="imageOnly" [src]="value.src"/>

      </div>

    </div>

    <div *ngIf="value && meta && ready">
      
      <div *ngIf="meta.elements[metaIndex].data.color || attr.color" [ngStyle]="meta.elements[metaIndex].data.multicolor ? {} : getFilter(meta.elements[metaIndex].data.color || attr.color)">

        <img [width]="(meta.elements[metaIndex].data.size * 2) || 32" [height]="(meta.elements[metaIndex].data.size * 2) || 32" (click)="action && action()" [ngStyle]="attr" [src]="meta.elements[metaIndex].data.value"/>

        <img class="hackload-img" *ngIf="imageOnly" [src]="meta.elements[metaIndex].data.value"/>

      </div>

    </div>

  </ng-container>

  <ng-container *ngIf="meta && (type === 'layer' && ready)">

    <!--
    (onBlur)="blure($event)"
    (onFocus)="enterViewEdit($event)"
    (onFocusOut)="blure($event)"
    -->

    <!--
    {{ meta | json }}
    {{ meta.elements[metaIndex].data | json }}
    -->

    <div *ngIf="meta.elements[metaIndex].data.value && !meta.elements[metaIndex].data.index" class="layer-base" [ngStyle]="{
      'height': '100%',
      'display': (meta.elements[metaIndex].data.show) ? 'block' : 'none',
      'background-color': meta.elements[metaIndex].data.color ? meta.elements[metaIndex].data.color : (meta.raw2color || '#fff')
    }">
      <!--
      Custom Image - Color
      -->
      <div *ngIf="meta.elements[metaIndex].data.value && !meta.elements[metaIndex].data.index" class="layer-base"
      [ngStyle]="ignoreInvalidAttr(attr)"
      >
        <!--
        Custom Image - Image
        -->
        <img class="hackload-img" *ngIf="imageOnly" [src]="meta.elements[metaIndex].data.value"/>

      </div>
    </div>

    <div *ngIf="meta.elements[metaIndex].data.index" class="layer-base"
    [ngStyle]="{
      'height': '100%',
      'display': (meta.elements[metaIndex].data.show) ? 'block' : 'none',
      'background-color': meta.elements[metaIndex].data.color ? meta.elements[metaIndex].data.color : (meta.raw2color || '#fff')
   }">
      <!--
      Index Image
      -->
      <div class="layer-base"
      [ngStyle]="ignoreInvalidAttr(attr, meta, meta && meta.elements ? {
        'transform': 'rotate(0deg)',
        'background-image': 'url(' +meta['raw' + meta.elements[metaIndex].data.index + 'layer']+ ')'
      } : {})"
      >
        <!--
        Custom Image - Image
        -->
        <img class="hackload-img" *ngIf="imageOnly" [src]="meta['raw' + meta.elements[metaIndex].data.index + 'layer']"/>

      </div>
    </div>

    <div *ngIf="!meta.elements[metaIndex].data.index && !meta.elements[metaIndex].data.value" class="layer-base" [ngStyle]="{'height': '100%', 'background-color': meta.elements[metaIndex].data.color ? meta.elements[metaIndex].data.color : (meta.raw2color || '#fff')}">
      <!--
      Index Blank
      -->
    </div>

  </ng-container>

  <ng-container *ngIf="type === 'root'">

    <ng-content></ng-content>

  </ng-container>

  <!--
  <ng-container *ngIf="type === 'cta'">

    <div class="cta" *ngIf="ready" [ngStyle]="{
      'background-color': attr['background-color']
    }">

      <editor
        #chatMCE
        class="font-{{ joinFont(attr.font) }}"
        [ngStyle]="ignoreInvalidAttr(attr)"
        [(ngModel)]="value"
        (onChange)="change($event)"
        (input)="change($event)"
        (onInit)="init()"

        [inline]="true"
        [init]="{
          paste_as_text: true,
          base_url: '/tinymce',
          suffix: '.min',
          skin: 'oxide-dark',
          placeholder: '',
          menubar: false,
          extended_valid_elements : 'b,i,u,div',
          forced_root_block : 'div',
          plugins: [
            'paste'
          ],
          toolbar:
            'italic bold underline'
        }"
      ></editor>

    </div>

  </ng-container>
  -->

  <ng-container *ngIf="type === 'select-container'">

    <div (click)="selectBlock.open()">
      <ng-content *ngIf="!focusSwitch" select="[select]"></ng-content>
    </div>
    <div class="select-exceptional-overlay">
      <ion-select class="silent-select" interface="popover" #selectBlock (ionChange)="change($event)" [(ngModel)]="value">
        <ion-select-option *ngFor="let item of options" [value]="item">{{ item }}</ion-select-option>
      </ion-select>
    </div>

  </ng-container>

  <ng-container *ngIf="type === 'text-container'">



    <ng-container *ngIf="true">

      <div [ngClass]="{ 'ion-hide': focusSwitch }" class="in-preview-mode" (click)="setFocus(true)" #inPreview>
        <ng-content  select="[text]"></ng-content>
      </div>

      <div [ngClass]="{ 'text-container': true, 'ion-hide': !focusSwitch }">
        <div *ngIf="attr.editor && attr.editor === 'tiny'">

          <editor
            #chatMCE
            class="element-text-container font-{{ joinFont(attr.font) }}"
            [ngStyle]="ignoreInvalidAttr(attr)"
            [(ngModel)]="value"
            (onChange)="change($event)"
            (input)="change($event)"
            (onInit)="init()"
            (onBlur)="setFocus(false)"

            [inline]="true"
            [init]="{
              paste_as_text: true,
              base_url: '/tinymce',
              suffix: '.min',
              skin: 'oxide',
              placeholder: '',
              menubar: false,
              extended_valid_elements : 'b,i,u,div',
              forced_root_block : 'div',
              plugins: [
                'paste charmap emoticons'
              ],
              toolbar:
                'charmap emoticons'
            }"
          ></editor>

        </div>

        <ion-textarea 
        *ngIf="!attr.editor" 
        [autoGrow]="attr.grow || false" 
        [rows]="attr.rows" 
        class="silent-textarea" 
        
        #textBlock 
        
        (ionFocus)="inFocus($event)" 
        (ionChange)="change($event)" 
        (ionBlur)="setFocus(false)" 
        [(ngModel)]="value"></ion-textarea>
      </div>

    </ng-container>

  </ng-container>

  <ng-container *ngIf="type === 'container'">

    <div>
      <ng-content select="[container]"></ng-content>
    </div>

  </ng-container>

</div>
