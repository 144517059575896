import { FormProcessorService } from '@services/form-processor.service';
import { Component, Input, OnInit } from '@angular/core';

import { DataSourceService } from '@app/-core/services/data-source.service';
import { ModalController } from '@ionic/angular';

import { Router } from '@angular/router';
import { FunnelsCoreService } from '@app/app-v2/funnels/-core/services/funnels-core.service';

import { AiService } from '@app/-core/services/ai.service';

import { DetailVariationPage } from '@app/app-v2/templates/detail-variation/detail-variation.page';

@Component({
  selector: 'app-asset-in-pack-create-modal',
  templateUrl: './asset-in-pack-create-modal.component.html',
  styleUrls: ['./asset-in-pack-create-modal.component.scss'],
})
export class AssetInPackCreateModalComponent implements OnInit {

  @Input() projectId;
  @Input() item;
  @Input() context;
  @Input() assetId;

  @Input() template;

  @Input() data: any = null;
  datax: any = null;
  datas: any = null;
  sets = {};

  activePreset;

  selectT(preset){
    //
    this.activePreset = preset;
  }
  public activeThemeList = null;

  preselectThemeList(item) {
    this.activeThemeList = item;
  }
  
  async ref(res) {
    // return;
    this.datas = res;

    await this.funnelsService.dataSource.viewTemplateVariations(this.activeThemeList.themes_list).subscribe( async (zres) => {
      try {
        res.custom_preview.forEach( item => {
          // console.log('item', item);
          this.funnelsService.dataSource.getTemplateSettingsById(this.data.id, item.content_type).subscribe( (res) => {
            console.log('built item', this.datas, item, zres);
  
            // console.log(res);
            if (!this.sets[item.content_type]) {
              this.sets[item.content_type] = [];
            }
  
            console.log('push', res);
  
            res.presets = res.presets.presets;
  
            res.presets.forEach( (preset) => {
              preset.combined = {...item, ...JSON.parse(JSON.stringify(preset.variables))} ;
              preset.combined.preset = preset.title;
            });
  
            this.sets[item.content_type].push(res);
  
          });
        });
  
        /*
        if ( totalPersetCount ) {
          console.log('totalPersetCount', totalPersetCount);
        }
        */
  
      } catch (err) {
        // -
      }
    });
    console.log('ref data', this.datas);
    // getTemplateSettingsById


  }
  


    selectThemeActive(item) {
      /*
      this.modalCtrl.dismiss({
        selected: item,
      });
      */
    }

  retryInterval;

  // data;
  ready = false;

  dataFetchSubscribe: any;

  hoveredThemeDescription: string = '';
  activePage = 1;
  totalPages = 3;

  activeIndex = 1;
  hotTopics = [
    {stepBtn: 'Product Name'},
    {stepBtn: 'Keto Core'},
  ];

  activeStep = 1;
  themeList = [
    { path: 'themes-default', title: 'Default' },
  ];
  selectedTheme = this.themeList[0];

  activeTheme = 0;

  loading: any = false;
  loadingInterval: any = null;
  loadingPhraseSet = [
    '...',

    'Launching your funnel now',
    'This thing is going to be amazing',
    'Preparing for world domination',
    'Did you know that the human head weighs 8 pounds?',
    'Fastest land animal?',
    'Cheetah... (You should know that)',
    'One sec',
    'Ok I am back',
    'Needed a quick AI nap',
    'Even AI has to rest, you know…',
    'Putting final touches on your funnel as we speak',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
    'Wait for it',
  ];

  welcomeText = '_';

  constructor(
    // public dataSource: DataSourceService,
    public modalController: ModalController,
    public router: Router,
    public funnelsService: FunnelsCoreService,
    // public formProcessor: FormProcessorService,
  ) {

  }

  renderGreeting() {
    return this.welcomeText;
  }

  ngOnInit() {
    this.activePage = 1;
    this.ready = false;

    console.log('cntx', this.assetId);
    // return;

    const campaignId = this.assetId;
    const themes_list = this.template.themes_list;

    this.dataFetchSubscribe = this.funnelsService.dataSource.getCourseCreatorData(campaignId, themes_list).subscribe( (res) => {
      console.log('this.dataSource.getRoadmaps', res);
      this.datax = res;
      this.ready = true;
    });
  }

  selectActiveItem(item) {

    try {


    } catch (err) {
    }

    this.data.selectedThemes = 0;
  }
  
  getPlaceholderUrl(): void {

  }

  move(dir) {
    if ( !!dir ) {
      this.activePage ++;
    } else {
      this.activePage --;
    }
    this.setActivePage(this.activePage, dir);
  }

  setActivePage(index, direction) {
    
    this.ready = false;

    this.activePage = index;
    if ( this.activePage === 0 ) {
      this.closeWizard();
    }
    
    if ( this.activePage === 1 ) {
      this.ready = false;
      this.ngOnInit();
    }

    if ( this.activePage === 2 ) {
      // Load Dynamic Font Data
      // based on selected theme [theme_preview]

      console.log('this.data.selectedItem.themes_data remnant');
      this.ref(this.data);
      
      // this.formProcessor.loadAndSetDynamicFontSet(fontStack);

    }
    if ( this.activePage === 3 ) {
      setTimeout( async () => {
        this.ready = true;

        this.startLoadingAnimation();

        this.modalController.dismiss(
          {
            selected: this.activePreset,
            loader: 'ai',
            themes_list: this.activeThemeList.themes_list,
          }
        );
        /*
        this.dataSource.createFunnel(this.id, resultData.data.funnelSlug, resultData.data.themeSlug, resultData.data.colorSlug).subscribe( (res) => {
          console.log('this.dataSource.createFunnel', res);
          setTimeout( () => {
            this.router.navigate(['/app/funnel/' + this.id + '/view/' + res.id + '/map']);
          }, 50);
        });
        */

      }, 500);
    }

  }

  checkLandingBackendBuilt(funnelId) {
    clearTimeout(this.retryInterval);

    const projectId = this.projectId; //
    
    this.dataFetchSubscribe = this.funnelsService.dataSource.getFunnelDetails(projectId, funnelId).subscribe( res => {

      if ( res && res.map_status === 'READY' ) {

        setTimeout( () => {
          this.closeWizard();
          this.router.navigate(['/app/funnel/' + projectId + '/view/' + res.id + '/map'], { queryParams: { generated_at: + new Date() } }).then(()=>{
            // do whatever you need after navigation succeeds
            setTimeout( () => {
              (window as any).location.reload();
            });
          });
        }, 2000);

      } else {

        this.retryInterval = setTimeout( () => {
          this.checkLandingBackendBuilt(funnelId);
        }, 5000);
      
      }

      // console.log('FUNNELS :: setActiveProject', this.activeFunnelData);
    }, err => {

      console.log('FUNNELS :: Error / Wait');
      this.retryInterval = setTimeout( () => {
        this.checkLandingBackendBuilt(funnelId);
      }, 5000);

    });
  }

  renderState(index) { // render center +- block renderer
    return index === this.activePage || index + 1 === this.activePage || index - 1 === this.activePage;
  } 

  dismiss() {
    this.closeWizard();
  }

  setActiveFunnel(index: number) {
    this.activeIndex = index;
    // console.log(index);
  }

  setActiveStep(index: number) {
    this.activeStep = index;
  }

  selectTheme(index: number) {
    this.selectedTheme = this.themeList[index];
    // this.selectedDescription = this.themeMockup[index].description;
    this.activeTheme = index;
  }



  closeWizard() {
    this.endLoadingAnimation();
    this.modalController.dismiss();
    if ( this.dataFetchSubscribe ) {
      this.dataFetchSubscribe.unsubscribe();
    }
    
  }

  currentLoadingPhrase() {
    if ( this.loading ) {
        return this.loadingPhraseSet[this.loading];
    } else {
        return this.loadingPhraseSet[0];
    }
  }

  startLoadingAnimation() {
    this.endLoadingAnimation();
    this.loading = 1;
    clearInterval(this.loadingInterval);
    this.loadingInterval = setInterval( () => {
        this.loading++;
        if (this.loading >= this.loadingPhraseSet.length) {
          this.loading = 1;
        }
    }, 2500);
  }

  endLoadingAnimation() {
    clearInterval(this.loadingInterval);
    this.loading = false;
  }

}

/*
"theme_preview": {
    "font": {
        "color": "#FFF",
        "weight": "400",
        "family:": "Roboto"
    },
    "background": "#000",
    "color_stack": [
        "#FFF",
        "#AAA",
        "#777"
    ]
}
*/