<ion-progress-bar color="primary" [value]="(activePage/totalPages)"></ion-progress-bar>

<div class="actions" *ngIf="activePage !== 3">
  <ion-button (click)="move(0)" *ngIf="activePage > 1" color="dark" fill="clear">Previous <ion-icon name="chevron-back-outline" slot="start"></ion-icon></ion-button>
  <ion-button class="ion-float-right" *ngIf="activePage > 0 && !(activePage === 1 && !activeThemeList) && !(activePage === 2 && !activePreset)" (click)="move(1)" color="primary" fill="solid">Next <ion-icon name="chevron-forward-outline" slot="end"></ion-icon></ion-button>
</div>

<ion-fab vertical="top" horizontal="end" slot="fixed">
  <ion-fab-button (click)="dismiss()">
    <ion-icon name="close"></ion-icon>
  </ion-fab-button>
</ion-fab>

<ion-content id="mainFrame" #mainframe>

  <div class="full-page-slide loading" [ngClass]="{'active-render': activePage === 0, 'prev-render': activePage === 1}" *ngIf="renderState(0)">
    <!-- Loading -->
    <div class="block">

      <div class="loading-box">
        <gw-ai-blob quality="lq"></gw-ai-blob>
        <div class="animated-text">{{ renderGreeting() }}</div>
      </div>

    </div>
  </div>

  <div class="full-page-slide select" [ngClass]="{'active-render': activePage === 1, 'prev-render': activePage === 2}"  *ngIf="renderState(1)">
    <!-- Select Funnel -->
    <div class="block">

      <div class="header">Generate New Asset<br><br><div><small>Choose Topic: <ion-icon class="ion-hide" name="information-circle-outline" title="Todo!"></ion-icon></small></div></div>

      <div class="funnel-boxes" *ngIf="this.datax && this.datax.length">

        <div class="button-cloud">

          <div class="button" (click)="preselectThemeList(item)" *ngFor="let item of this.datax; let i = index;">
            <div class="box" [ngClass]="{ 'active': activeThemeList && (activeThemeList.themes_list === item.themes_list)}">
              <div class="mark" *ngIf="false"></div>
              <div>{{ item.label }}</div>
            </div>
          </div>

        </div>

      </div>

    </div>
  </div>

  <div class="full-page-slide themes" [ngClass]="{'active-render': activePage === 2, 'prev-render': activePage === 3}"  *ngIf="renderState(2)">
    <div class="block" style="
    width: 100%!important;
    max-width: max-content!important;
    overflow-y: scroll!important;
    max-height: calc(100vh - 0px)!important;">

      <div class="header"><br/><br/>Choose Your Theme<br/><br/></div>

      <ion-grid class="themes-set" *ngIf="sets">
        <ion-row *ngFor="let set of sets | keyvalue">
    
          <ng-container *ngFor="let item of set.value">
    
            <ng-container *ngFor="let preset of item.presets">
    
              <ion-col [title]="preset.title" class="ion-text-center" *ngIf="preset.is_active" size="3" (click)="selectT(preset.combined)">
                <img [src]="preset.thumb" class="theme-item" [ngClass]="{ 'active': activePreset && (preset.combined.preset === activePreset.preset) }" />

              </ion-col>
            </ng-container>
    
          </ng-container>
    
        </ion-row>
      </ion-grid>
    

    </div>
  </div>

  <div class="full-page-slide" [ngClass]="{'active-helper': ready, 'active-render': activePage === 3, 'prev-render': activePage === 4}"  *ngIf="renderState(3)">

    <gw-ai-assistant

    #assistantBubble 

    [tipColor]="loading" 
    
    direction="down" 
    quality="hq" 
    [processingText]="currentLoadingPhrase()" 
    [processing]="loading" 

    ></gw-ai-assistant>

  </div>

</ion-content>


