import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap, tap, map, delay, finalize } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { NabuApiService } from '@services/api/nabu-api.service';
import { DataSourceService } from '@services/data-source.service';

import { ModalController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { templates } from '../../app/manage/script/templates';

// Subscription Status

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // Load and store user related data, profile data + arrays of followings
  public data: any;
  public hasMainToken: any;

  constructor(public api: NabuApiService,
              public dataSource: DataSourceService,
              public storage: Storage,

              private modalController: ModalController,
              private toastController: ToastController,
              public alertController: AlertController,
              private router: Router) {

    this.data = {};

    this.getTokenChange().subscribe(value => {
      console.log('getTokenChange', value);
      if ( !this.isLogged() ) {
        // Logout clear data store
        this.data = {};
      } else {
        this.toastController.create({
          message: 'You have been successfully logged in!',
          position: 'bottom',
          color: 'success',
          duration: 3000
        }).then((res) => {
          res.present();

          try {
            (window as any).injectIntercom();
          } catch (err) {
            console.log('window.injectIntercom();', err);
          }

          // this.auth.presentFollowModal();
          // unpach any pending deeplinks
          setTimeout( async () => {

            const action = await this.storage.get('deep-link').then();
            console.log(action);
            if ( action ) {
              this.unpackDeepLink(action);
              this.storage.remove('deep-link');
              // alert('unpack deeplinks' + action);
            }
          }, 2000);
        });

        // We are logged, so then try to load stores and profile from storage then actualize from web
        this.storage.get('currentUser')
        .then(res => {
          this.data = res || {};
          // Actualize from web
          this.synchronizeFromWeb();
        }, err => {
          this.data = {};
        });
      }
    });

    this.storage.get('recoverAuthToken')
    .then( (res) => {
      this.hasMainToken = res;
    });

    // Custom Console Utilities  for Auth / Permissions debug
    //   // Send Event To Notify Angular context (AuthService)
    // recieve event
    (window as any).document.addEventListener('gwUserEntity', (event) => {
      const key = event.detail.key;
      const val = event.detail.value || null;

      console.log('gwUserEntity', key, val);
      
      if ( !key ) {
        console.log('%cgwUserEntity - authService.data', 'color: orange', this.data);
      } else {
        this.data[key] = val;
        console.log('%cgwUserEntity - authService.data Changed', 'color: lime', this.data);
      }
      // this.data[event.detail.key] = event.detail.value;
    });

  }

  public authAppendPathtroughParams(url) {
    // append params as ?passthrough[customer_name]=Edward%20Mann&passthrough[customer_email]=mremann%40example.com
    // if present
    let passthrough = {};
    if ( this.data.first_name ) {
      passthrough['customer_firstname'] = this.data.first_name;
    }
    if ( this.data.last_name ) {
      passthrough['customer_lastname'] = this.data.last_name;
    }
    if ( this.data.email ) {
      passthrough['customer_email'] = this.data.email;
    }
    // passthrough array as passthrough[key]=value
    let passthroughStr = '';
    for (const key in passthrough) {
      if (passthroughStr.length) {
        passthroughStr += '&';
      }
      passthroughStr += 'passthrough[' + key + ']=' + passthrough[key];
    }

    try{
      if (passthroughStr.length) {
        // if url contains ? already use & instead
        if ( url.indexOf('?') !== -1 ) {
          url += '&' + passthroughStr;
        } else {
          url += '?' + passthroughStr;
        }
      }
      // console.log('GIVEUP:', url);
      return url;
    } catch (err) {
      return null;
    }

  }

  private unpackDeepLink(action) {
    if ( action === 'upgrade' ) {
      // /rest-auth/growthworks/upgrade-to-coach/
      const observable = this.api.get('rest-auth/growthworks/upgrade-to-coach/', {}, {}, {preloader: true});
      observable.subscribe( async (sr) => {
        console.log('res', sr);
        if (sr.status === 'ok') {
          const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: 'Upgrade to a coach',
            inputs: [
              {
                name: 'name',
                type: 'text',
                placeholder: 'Your agency name'
              },
            ],
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  console.log('Confirm Cancel');
                }
              }, {
                text: 'Submit',
                handler: (sres) => {
                  console.log('Confirm Ok');
                  if ( sres.name && sres.name.length ) {
                    // sres
                    const observable1 = this.api.post('rest-auth/growthworks/upgrade-to-coach/', sres, {}, {preloader: true});
                    observable1.subscribe( async (tres) => {
                      const su = await this.toastController.create({
                        message: 'You have been successfully upgraded to coach!',
                        position: 'middle',
                        color: 'success',
                        duration: 3000
                      });
                      await su.present();
                      (window as any).location = '/';
                    }, async (err) => {
                      this.unpackDeepLink('upgrade');
                      const su = await this.toastController.create({
                        message: 'Agency with this name already exists.',
                        position: 'bottom',
                        color: 'warning',
                        duration: 3000
                      });
                      await su.present();
                    });

                    return true;
                  }
                  return false;
                }
              }
            ]
          });

          await alert.present();
        }
      });
    }
  }

  public synchronizeFromWeb() {
    console.log('synchronizeFromWeb()');
    this.dataSource.loadMeta();
    this.dataSource.getProfile().subscribe( (res) => {
      
      let subscription;
      try{
        if ( this.data.subscription ) {
          subscription = this.data.subscription;
        }
      } catch (err) {

      }

      this.data = res || {};

      // WAS - this.data.partially_templates_upgrade_link = null;
      // this.data.upgrade_unlimited_link = null;

      if ( subscription ) {
        this.data.subscription = subscription;
      }

      this.storage.set('currentUser', this.data);

      this.getSubscriptionData().subscribe( (subRes) => {
        subRes.update_ts = new Date().valueOf();
        this.data.subscription = subRes;
        this.storage.set('currentUser', this.data);
      });

    });
  }

  // End subjective interactions

  public verifiedStatus(): Observable<any> {
    const observable = this.api.get('rest-auth/approved/', {}, {}, {preloader: true});
    return observable;
  }

  /*
  public signUp(data: object): Observable<any> {
    // Depends if referer hash is in place. then auth via different url

    const storageObservable = from(this.storage.get('userRef'));
    const syncObservable = storageObservable.pipe(
      switchMap((ref) => {
        console.log('Refs: ', ref);
        let observable;
        if ( ref ) {
          observable = this.api.post('rest-auth/referral/' + ref + '/', data, {}, {preloader: true});
        } else {
          observable = this.api.post('rest-auth/register/', data, {}, {preloader: true});
        }
        return observable;
      })
    );

    return syncObservable;

  }
  */

  public signUp(data: object): Observable<any> {
    const observable = this.api.post('rest-auth/growthworks/register/', data, {}, {preloader: true});
    return observable;
  }

  /*
  public signUpRef(data: object): Observable<any> {
    const observable = this.api.post('rest-auth/referral/' + (data as any).ref + '/', data, {}, {preloader: true});
    return observable;
  }
  */

  public signIn(data: object): Observable<any> {
    const observable = this.api.post('rest-auth/login/', data, {}, {preloader: true});
    return observable;
  }

  public requestReset(data: object): Observable<any> {
    const observable = this.api.post('rest-auth/password/reset/', data, {}, {preloader: true});
    return observable;
  }

  public confirmReset(data: object): Observable<any> {
    const observable = this.api.post('rest-auth/password/reset/confirm/', data, {}, {preloader: true});
    return observable;
  }

  public getSubscriptionData(): Observable<any> {
    const observable = this.api.get('api/v1/billing/stripe/balance/', {}, {}, {preloader: false});
    return observable;
  }

  public logout(redirect = false) {
    this.api.post('rest-auth/logout/', {}, {}, {noInternet: false}).subscribe();
    this.storage.remove('userAuthorized');
    this.storage.remove('authToken')
    .then(res => {
        this.api.activeAuthTokenChange.next(null);
        console.log(res);
        // this.router.navigateByUrl('/signin').then();
        if ( redirect ) {
          this.router.navigateByUrl('/').then();
        }
    }, err => console.log(err));
  }

  public setToken(key) {
    this.storage.set('authToken', key);
    this.api.activeAuthTokenChange.next(key);
  }

  public getToken() {
    return this.api.activeAuthToken;
  }

  public getTokenChange() {
    return this.api.activeAuthTokenChange;
  }

  public isLogged() {
    return (this.getToken()) ? true : false;
  }

}
