import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BaseApiService } from './base/base-api.service';
import { ApiServiceInterface } from './interfaces/api-service-interface';

import { Router } from '@angular/router';
import { Observable, throwError, from, timer, interval, of } from 'rxjs';

import { catchError, switchMap, tap, map, delay, finalize, retryWhen, delayWhen, take, mergeMap, concatMap, filter } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { Subject } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';

import { ToastController, AlertController, LoadingController } from '@ionic/angular';


export const genericRetryStrategy = ({
  maxRetryAttempts = 3,
  scalingDuration = 1000,
  excludedStatusCodes = []
}: {
  maxRetryAttempts?: number,
  scalingDuration?: number,
  excludedStatusCodes?: number[]
} = {}) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      // if maximum number of retries have been met
      // or response is a status code we don't wish to retry, throw error
      if (
        retryAttempt > maxRetryAttempts ||
        excludedStatusCodes.find(e => e === error.status)
      ) {
        return throwError(error);
      }
      console.log(
        `Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`
      );
      // retry after 1s, 2s, etc...
      return timer(retryAttempt * scalingDuration);
    }),
    finalize(() => console.log('We are done!'))
  );
};

@Injectable({
    providedIn: 'root'
})
export class NabuApiService implements ApiServiceInterface {

    private apiUrl: string = environment.apiUrl;

    public sharedLoader;
    private sharedLoaderIncrement = 0; 

    public activeAuthToken: any = null;
    public activeAuthTokenChange: Subject<any> = new Subject<any>();

    constructor(
      public api: BaseApiService,
      private router: Router,
      public toastController: ToastController,
      public loadingController: LoadingController,
      public alertController: AlertController,
      private storage: Storage 
      ) {

        console.log('%cSharedLoader - create loader', 'color: lime;');

        this.activeAuthTokenChange.subscribe((value) => {
          console.log('activeAuthToken is set');
          this.activeAuthToken = value;
        });

        this.storage.get('authToken').then((token) => {
          console.log('Token Restored', token);
          // this.activeAuthToken = token;
          this.activeAuthTokenChange.next(token);
        });

        this.activeAuthTokenChange.next(null);

    }

    /**
     * Prepare URL for request to Artexpert API
     *
     */
    /*
    private _url(url) {
        return this.apiUrl + '/' + url;
    }
    */

    /**
     * GET
     *
     */
    get(url: string, queryParams?: object, options?: object, mods?: object): Observable<any> {
        const opts = {
            ...options,
            params: queryParams,
        };

        return this.request('GET', url, opts, mods);
    }

    /**
     * POST
     *
     */
    post(url: string, data?: object, options: object = {}, mods?: object): Observable<any> {
        const opts = {
            ...options,
            body: data,
        };

        return this.request('POST', url, opts, mods);
    }

    /**
     * PUT
     *
     */
    put(url: string, data?: object, options?: object, mods?: object): Observable<any> {
        const opts = {
            ...options,
            body: data,
        };

        return this.request('PUT', url, opts, mods);
    }

    /**
     * DELETE
     *
     */
    delete(url: string, data?: object, options?: object, mods?: object): Observable<any> {
        const opts = {
            ...options,
            body: data,
        };

        return this.request('DELETE', url, opts, mods);
    }

    /**
     * PATCH
     *
     */
    patch(url: string, data?: object, options?: object, mods?: object): Observable<any> {
        const opts = {
            ...options,
            body: data,
        };

        return this.request('PATCH', url, opts, mods);
    }

    /**
     * Universal request to Artexpert API
     *
     */
    request(method: string, url: string, options: object, mods?:any): Observable<any> {

        console.log('request', method, url, options, mods);


        // List default request modules
        const activeMods = {
          backendErrors: false, // if true generic messages will be shown
          noInternet: true, // if true no internet will be triggered upon bad response
          preloader: false, // if true preloader will be shown at start and end of request
          retries: 3,
          retryDelay: 3000,

          // automatic toasters on datasource invokation
          messageSuccess: null,
          messageWarning: null,
          messageError: null, 

          // also heavy alert options
          alertSuccess: null, // { title: 'Success', message: 'Operation completed successfully.' },
          alertError: null, // ,{ title: 'Error', message: 'Operation failed.' },

          ...mods
        };

        this.apiUrl = environment.apiUrl;
        if ( mods && mods.useEndpoint ) {
          this.apiUrl = environment[mods.useEndpoint];
        }

        // console.log(mods, activeMods);

        if ( activeMods.preloader ) {
          const render_loader = async () => {
            this.sharedLoader = await this.loadingController.create({
              message: 'Loading...',
              translucent: true,
            });
            try{
              
              this.sharedLoaderIncrement++;
              console.log('%cSharedLoader - render loader', 'color: lime;', this.sharedLoaderIncrement);

              await this.sharedLoader.present();
            } catch (err) {

            }
          };
          render_loader();
        }

        const storageObservable = from(this.storage.get('authToken'));
        return storageObservable.pipe(delay(activeMods.beingRetried ? activeMods.retryDelay : 1)).pipe(
          switchMap((token) => {

            // console.log(token, this.storage.driver);

            if ( token != null ) {
              this.api.setHeader('Authorization', 'Token ' + token);
            } else {
              this.api.unsetHeader('Authorization');
            }

            return this.api.request(method, this.apiUrl + '/' + url, options).pipe(
                // add success handler
                tap((data) => {
                  console.log(`request tap`, data);

                  if ( activeMods.messageSuccess ) {
                    (async (tip) => {
                      const toast = await this.toastController.create({
                        message: tip,
                        duration: 5000,
                        color: 'success',
                        // showCloseButton: true
                      });
                      toast.present();
                    })(activeMods.messageSuccess);
                  }

                  if ( activeMods.alertSuccess && activeMods.alertSuccess.title && activeMods.alertSuccess.message ) {
                    (async (tip) => {
                      const alert = await this.alertController.create({
                        header: tip.title,
                        message: tip.message,
                        buttons: ['OK']
                      });
                      await alert.present();
                    })(activeMods.alertSuccess);
                  }

                }),
                finalize( () => {
                  console.log(`request finalize`);

                  try {
                    if ( this.sharedLoader ) {
                      // this.sharedLoader.dismiss();
                      this.sharedLoaderIncrement--;
                      console.log('%cSharedLoader - hide loader - on success', 'color: lime;', this.sharedLoaderIncrement);
                      this.unloadLoaderStack();
                    }
                  } catch (err) {
                    // err
                  }
                }),
                catchError( (error) => {
                  console.log(`request catchError`, error);

                  if ( error.status === 502 || error.status === 504 ) {

                    activeMods.retries--;
                    if ( activeMods.retries >= 0 ) {
                      activeMods.beingRetried = true;
                      return this.request(method, url, options, activeMods);
                    } else {
                      this.onModError(error, activeMods);
                      return throwError(error);
                    }

                  } else {

                    try {
                      if ( this.sharedLoader ) {
                        // this.sharedLoader.dismiss();
                        this.sharedLoaderIncrement--;
                        console.log('%cSharedLoader - render loader - on error', 'color: lime;', this.sharedLoaderIncrement);
                        this.unloadLoaderStack();
                      }
                    } catch (err) {
                      // err
                    }

                    this.onModError(error, activeMods);
                    return throwError(error);

                  }

                })
            );
          })
        );
    }

    public setHeader(header: string, value: string) {
        this.api.setHeader(header, value);
    }

    public unsetHeader(header: string) {
        this.api.unsetHeader(header);
    }

    private unloadLoaderStack() {
      setTimeout( () => {
        if ( this.sharedLoaderIncrement <= 0 ) {
          this.sharedLoaderIncrement = 0;
          setTimeout( () => {
            this.sharedLoader.dismiss();
          });
        }
      });
    }

    onError(error): void {
      if (error.error.message) {
          console.log(error.error.message);
      }
    }

    /**
     * Errors handler
     *
     */
    onModError(error, mods): void {

        console.log('onModError', error, mods);

        // error toaster
        if ( mods.messageError ) {
          (async (tip) => {
            const toast = await this.toastController.create({
              message: tip,
              duration: 5000,
              color: 'danger',
              // showCloseButton: true
            });
            toast.present();
          })(mods.messageError);
        }

        if ( mods.messageWarning ) {
          (async (tip) => {
            const toast = await this.toastController.create({
              message: tip,
              duration: 5000,
              color: 'warning',
            });
            toast.present();
          })(mods.messageWarning);
        }

        // alertError - { title: 'Error', message: 'Operation failed.' }
        if ( mods.alertError && mods.alertError.title && mods.alertError.message ) {
          (async (tip) => {
            const alert = await this.alertController.create({
              header: tip.title,
              message: tip.message,
              buttons: ['OK']
            });
            await alert.present();
          })(mods.alertError);

        }

        // Foreign namespace/ or physical 404 page (not tost)
        if (error.status === 417) {
          this.router.navigateByUrl('/auth/wander').then();
          return;
        }

        // Support suspend option (402) - agency
        if (error.status === 402) {
            this.router.navigateByUrl('/auth/suspended').then();
            return;
        }

        // Suppor account lock (billing) option is code not jet defined - regular user
        if (error.status === 423 ) {
          this.router.navigateByUrl('/auth/locked').then();
          return;
        }

        if (error.status === 401) {
          console.log('User not authorized, deauth and prompt');
          this.post('rest-auth/logout/', {}, {}, {noInternet: false}).subscribe();
          this.storage.remove('authToken');
          // this.activeAuthToken = null;
          this.activeAuthTokenChange.next(null);
          this.router.navigateByUrl('/auth/logout').then();
          // SHOW TIP OF USER BEING UNLOGGED
          (async () => {
            const toast = await this.toastController.create({
              message: 'Your session is timed out, please log-in again.',
              duration: 5000,
              // showCloseButton: true
            });
            toast.present();
          })();

          return;
        }

        // mods.backendErrors = true;
        let message;
        if ( (error.status === 400 || error.status === 403 || error.status === 404 || error.status === 500) && mods.backendErrors ) {
          // SHOW TIP OF BACKEND ERROR !
          // 400 - controlled backend error.
          // 404 - controlled backend error (not found).
          // 500 - uncontrolled backend error.
          message = error.status.toString();
          switch ( error.status ) {
            case 400: message = 'Server error occured.'; break;
            case 403: message = 'You do not have permission to perform this action.'; break;
            case 404: message = 'Unable to find record in our database.'; break;
            case 500: message = 'Unknown server error occured.'; break;
          }
        }

        if ( error.status === 400 && error.error && error.error.errors ) {
          console.log('error.error', error.error.errors);
          message = error.error.errors.join('<br>');
        }

        if ( message ) {
          (async (tip) => {
            const toast = await this.toastController.create({
              message: tip,
              duration: 5000,
              color: 'danger',
              // showCloseButton: true
            });
            toast.present();
          })(message);
          return;
        }

        if ( (error.status === 0) && mods.noInternet ) {
          // SHOW TIP THAT THERE IS NO INTERNET !
          (async () => {
            const toast = await this.toastController.create({
              header: 'No internet !',
              message: 'Unable to perform request, please check you connection.',
              duration: 2000,
              color: 'warning',
              position: 'top',
              // showCloseButton: true
            });
            toast.present();
          })();
          return;
        }

        if (error && error.error && error.error.message) {
            console.log(error.error.message);
        }
        
    }
}
