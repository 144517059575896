import { Component, OnInit, Input } from '@angular/core';
import { BrainstormingService } from '@app/-core/brainstorm-ai/brainstorming.service';
import { FormProcessorService } from '@services/form-processor.service';

import { Storage } from '@ionic/storage';
import { PopoverController } from '@ionic/angular';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'brainstorming-user-interface',
  templateUrl: './user-interface.component.html',
  styleUrls: ['./user-interface.component.scss'],
})
export class BrainstormingUserInterfaceComponent implements OnInit {

  // state: any = 0;
  generateMode: any = false;

  // constructor()
  brainstorming: any;

  textareaValue: string = '';

  @Input() variable: any = 'Not Set';


  selectCategory(category) {
    this.brainstorming.setCategory(category);
    this.brainstorming.setState(1);
  }
  selectStep(step) {
    //
  }
  mainCategory: any = 'email';
  mainStep: any = 'general';

  backClear() {
    const options = {
      header: 'All results will be deleted after going back',
      message: 'Are you sure you want to go back?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes, Go Back',
          cssClass: 'danger',
          handler: () => {
            this.brainstorming.clearSet(true);
            this.brainstorming.setState(1);
          }
        }
      ]
    };
    this.formProcessor.showComplexMessage(options);
  }

  prepareOptions(cat) {
    const res = [];

    if ( this.brainstorming && this.brainstorming.ui ) {

      if ( this.brainstorming.ui[cat] ) {
        this.brainstorming.ui[cat].forEach((element) => {
          if ( cat === 'group_selector_data' ) {
            res.push({
              label: element.title,
              value: element.ai_flow_slug,
            });
          }
        });
      }

    }

    return null;

  }

  constructor(
    // private brainstorming: BrainstormingService
    public formProcessor: FormProcessorService,
    public storage: Storage,
    public popoverController: PopoverController,
    public auth: AuthService,
  ) { }

  ngOnInit() {
    this.textareaValue = this.brainstorming.getText();
  
    if ( this.auth.data.is_superstaff ) {
      this.toggleDebug = true;
    }


  }

  ngOnDestroy() {
    const trimmedContent = ''; // Declare and assign a zero value to the variable
    this.brainstorming.setText(trimmedContent); 
  }
  
  toggleDebug = false;

  public async closeWidget() {

    const action = () => {

      this.brainstorming.forgetContext();
      // this.brainstorming.ui = null;
      // this.brainstorming.contextPayload = {};
      this.brainstorming.setState(-1);

      setTimeout(() => {
        this.brainstorming.hideUserInterface();
      }, 50);


    }

    const isClosedOnce = await this.storage.get('isClosedOnce');
    if (!isClosedOnce) {
      
      const alert = await this.brainstorming.alertController.create({
        header: 'Are you sure you want to close this widget?',
        message: 'All history will be deleted after closing window',
        cssClass: 'application-v2 alert-v2 no-icon',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          }, {
            text: 'Close Widget',
            cssClass: 'danger',
            handler: async () => {
              await this.storage.set('isClosedOnce', true);
              
              action();
            }
          }
        ]
      });
      return await alert.present();

    } else {
      
      action();
      
    }

  }  

  public setupInit = {
    cat: false,
    step: false,
  }


  blurSetup(state, ev) {

    setTimeout(   () => {

      if ( !this.setupInit[state] ) {
        this.setupInit[state] = true;
        console.log('Blur setup', state, this.brainstorming.ui.selectedCategory, ev);
      } else {
        
        // this.brainstorming.contextPayload.selectedCategory = this.brainstorming.ui.selectedCategory;
        // this.brainstorming.contextPayload.selectedPromptCategory = this.brainstorming.ui.selectedPromptCategory;

        
        if ( state === 'cat' ) {
          this.brainstorming.selectCategory(this.brainstorming.ui.selectedCategory);
        } 
        if ( state === 'step' ) {
          this.brainstorming.selectStep(this.brainstorming.ui.selectedStep);
        }
          

      }

    }, 50);
  }

  processQuery(payload, customPromptInput = false) {

    // preset Payload

    console.log('Processing query', payload);
    // this.brainstorming.setState(-1);
    let load = {
      ...payload, 
    };

    if ( this.brainstorming.contextPayload.selectedStep ) {
      load['step_index'] = (+this.brainstorming.contextPayload.selectedStep)-0;
    }

    setTimeout(() => {
      this.brainstorming.processQuery(
        load,
        customPromptInput
      );
    }, 50);
  }

  resetTextarea() {
    this.textareaValue = '';
    this.brainstorming.setText('');
  }  

  copyResult(text: string) {
    console.log('Copying results: ', text);
    const copied = text;
    this.formProcessor.copyToClipboard(copied);
  }

  //

  stopProcessing() {
    this.brainstorming.stopProcessing();
    // this.brainstorming.setState(0);
  }

  async presentResultOptionsPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: BrainstormingResultsOptionsComponent,
      cssClass: 'inverted-popover application-v2 popover-v2 ai-widget-results-popover',
      event: ev,
      translucent: true,
      showBackdrop: false
    });
    await popover.present();
    const data = await popover.onDidDismiss();
    console.log(data);
    if ( data.data && data.data.action === 0 ) {
      this.brainstorming.clearSet(true);
      this.brainstorming.setState(-1);
    }
    if ( data.data && data.data.action === 1 ) {
      this.brainstorming.clearSet(false);
    }
    if ( data.data && data.data.action === 2 ) {
      this.brainstorming.clearSet(true);
    }
  }

  setMode(mode) {
    this.brainstorming.setMode(mode);
  }

}

@Component({
  selector: 'brainstorming-results-options',
  templateUrl: './results-options.popover.html',
})
export class BrainstormingResultsOptionsComponent {

  constructor(
    public formProcessor: FormProcessorService,
    public popoverController: PopoverController,
  ) { }

  copyResult() {
    console.log('Copying results');
  }

  shareResult() {
    console.log('Sharing results');
  }

  action(index) {
    console.log('Action on results');
    this.popoverController.dismiss({
      action: index
    });
  }

}

// Component no results
@Component({
  selector: 'brainstorming-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./user-interface.component.scss'],
})
export class BrainstormingNoResultsComponent {

  constructor() { }

}