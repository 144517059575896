import { Directive, HostListener, Input, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

import { PopoverController } from '@ionic/angular';
import { CustomDropdownPopoverComponent } from './popover/popover.component';

import { NgModel } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[customDropdown]',
})
export class InvokeDropdownDirective implements AfterViewInit {

  @Input('customDropdown') dropdownOptions: any;
  private removeClickListener: () => void;
  private unsubscribe$ = new Subject<void>();

  public currentValue: any;

  private newElement: any;

  constructor(
    public popoverController: PopoverController, 
    private el: ElementRef, 
    private renderer: Renderer2,
    private ngModel: NgModel
  ) {
    // console.log('this.el.nativeElement customDropdown dropdownOptions', this.dropdownOptions, {el: this.el.nativeElement});
    // this.renderer.addClass(this.el.nativeElement, 'non-interactive');
  }
  
  ngAfterViewInit() {
    // console.log('this.el.nativeElement', this.el.nativeElement);

    this.el.nativeElement.classList.add('custom-dropdown-injected');

    this.newElement = this.renderer.createElement('div');
    this.renderer.setProperty(this.newElement, 'innerText', 'New Element');
    this.renderer.addClass(this.newElement, 'native-input');
    this.renderer.addClass(this.newElement, 'sc-ion-input-md');
    this.renderer.addClass(this.newElement, 'display-masked-value');

    this.renderer.setStyle(this.newElement, 'cursor', 'pointer');

    

    this.renderer.appendChild(this.el.nativeElement, this.newElement);

    try{

      if ( this.ngModel && this.ngModel.valueChanges ) {
        this.ngModel.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(value => {
    
          let res: any = [];

          this.dropdownOptions.options.forEach(option => {
            // console.log('this.el.nativeElement option', option, {value});
            let vals = [];
            if ( Array.isArray(value) ) {
              vals = value;
            } else if ( value ) {
              vals.push(value);
            }

            if ( option.value ) {
              
              vals.forEach(val => {
                // console.log('checkAgainstValues', { vals, val, option});
                if ( option.value == val ) {
                  res.push(option.label);
                }
              });

            } else if ( option.group && option.group.options && option.group.options.length > 0) {
              option.group.options.forEach(groupOption => {
                if ( groupOption.value ) {
                  // console.log('this.el.nativeElement groupOption option', {groupOption, option, value, vals});
                  vals.forEach(val => {
                    // console.log('checkAgainstValues', { vals, val, option});
                    if ( groupOption.value == val ) {
                      // res += groupOption.label + ', ';
                      res.push(groupOption.label);
                    }
                  });

                }
              });
            }
          });

          // filter out duplicates
          res = res.filter((v, i, a) => a.indexOf(v) === i);
          // join
          res = res.join(', ');

          // console.log('this.el.nativeElement ngModel value changed', value);
          if ( res ) {
            this.renderer.setStyle(this.newElement, 'color', 'inherit');
            this.renderer.setProperty(this.newElement, 'innerText', '' + res);
          } else {
            this.renderer.setStyle(this.newElement, 'color', '#999');
            const placeholder = this.el.nativeElement.getAttribute('placeholder') || '&nbsp;';
            this.renderer.setProperty(this.newElement, 'innerHTML', placeholder);
          }
          
          this.currentValue = value;
          
        });
      }
    } catch (er) {

    }

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    console.log('this.el.nativeElement customDropdown dropdownOptions', this.dropdownOptions, {el: this.el.nativeElement});
    this.presentPopover(event);
  }

  async presentPopover(ev: any) {
    this.currentValue = this.ngModel.value;

    const popover = await this.popoverController.create({
      component: CustomDropdownPopoverComponent,
      cssClass: 'application-v2 popover-v2 custom-dropdown',
      event: ev,
      translucent: true,
      backdropDismiss: true,
      showBackdrop: false,
      componentProps: {
        dropdownOptionsOriginal: this.dropdownOptions,
        value: this.currentValue
      }
    });
    await popover.present();

    this.el.nativeElement.classList.add('has-focus');

    const { role } = await popover.onDidDismiss();
    // console.log('onDidDismiss resolved with role', role);

    this.el.nativeElement.classList.remove('has-focus');

    if ( this.dropdownOptions.change ) {
      // modify ngValue
      this.ngModel.update.emit(this.dropdownOptions.change);
      this.dropdownOptions.change = null;

    }
  }

}
