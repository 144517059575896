<div class="application-v2">
<div>

  <div>

    <div [routerLink]="['/app/view/profile']" [routerLinkActive]="['active']" (click)="dismiss()" class="menu-item no-margin">
      <div class="label">
        <div class="icon">
          <ion-icon name="person-outline"></ion-icon>
        </div>
        <div class="title">Profile</div>
      </div>
    </div>

    <div *ngIf="this.auth.data.is_staff" [routerLink]="['/app/view/billing']" [routerLinkActive]="['active']" (click)="dismiss()" class="menu-item no-margin">
      <div class="label">
        <div class="icon">
          <ion-icon name="card-outline"></ion-icon>
        </div>
        <div class="title">Billing</div>
      </div>
    </div>

    <div (click)="intercomSupport()" class="menu-item no-margin">
      <div class="label">
        <div class="icon">
          <ion-icon name="help-buoy-outline"></ion-icon>
        </div>
        <div class="title">Help Center</div>
      </div>
    </div>
    

    <div *ngIf="this.auth.data.is_staff" (click)="openDesignMap()" class="menu-item no-margin">
      <div class="label">
        <div class="icon">
          <ion-icon name="color-palette-outline"></ion-icon>
        </div>
        <div class="title">View Design Kit</div>
      </div>
    </div>

    <div (click)="formProcessor.upgradeProfileModal('subscription')" class="menu-item no-margin">
      <div class="label">
        <div class="icon">
          <ion-icon name="caret-up-circle-outline"></ion-icon>
        </div>
        <div class="title">Manage Subscription</div>
      </div>
    </div>


    <div [routerLink]="['/auth/logout']" (click)="dismiss()" class="menu-item border-top no-margin ion-color-danger">
      <div class="label">
        <div class="icon">
          <ion-icon name="log-out-outline"></ion-icon>
        </div>
        <div class="title">Log out</div>
      </div>
    </div>

  </div>

</div>
</div>
